<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="row m-0 p-0 row-cols-1 m-0 row-cols-lg-2">
            <div class="p-0 m-0 px-lg-4 py-4 mx-lg-4 leftr col-12 col-lg-8 w-100">
                <Breadcrumbs :breadcrumbs="breadcrumbs" />
                <h1 class="mt-4">Login</h1>
                <p class="paragraph-text text-white">Je moet eerst inloggen om naar het administratiepaneel te kunnen gaan.</p>
                <hr/>
                <form method="POST" :action="postUrl" id="loginForm">
                    <div class="row row-cols-1">
                        <div class="col">
                            <h4 class="text-white">E-mailadres</h4>
                        </div>
                        <div class="col col-sm-12 col-md-8 col-lg-6">
                            <input type="text" id="email" class="form-control rounded-1" placeholder="E-mailadres">
                        </div>
                    </div>
                    <div class="row row-cols-1 mt-4">
                        <div class="col">
                            <h4 class="text-white">Wachtwoord</h4>
                        </div>
                        <div class="col col-sm-12 col-md-8 col-lg-6">
                            <input type="password" id="password" class="form-control rounded-1" placeholder="Wachtwoord">
                        </div>
                    </div>
                    <div class="row row-cols-1 mt-4">
                        <div class="col">
                            <button type="submit" class="btn btn-gold fw-bold"><i class="fa fa-lock-open me-1"></i> Inloggen</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
// import bcrypt from 'bcrypt';

import FlexBanner from '@/components/FlexBannerComponent.vue';
export default {
    name: 'admin-login',
    components: {
        FlexBanner,
    },
    data() {
        return {
            postUrl: "https://www.vorm-vrij.nl/scripting/login.php",
        }
    },
    mounted() {
        $("#loginForm").on('submit', (e) => {   
            e.preventDefault();
            this.login();
        })
    }
}
</script>