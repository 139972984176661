<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="mt-4">Nieuws</h1>
            <p class="paragraph-text text-white">Op deze pagina kun je het laatste nieuws volgen van wat ik op de website plaats.</p>

            <hr/>

            <template v-for="article in articles" :key="article.title">
                <h4 class="text-white m-0 mb-1">{{ article.title }}</h4>
                <div class="text-white bg-dark my-2 p-2 paragraph-text rounded-2 d-inline-block px-3">Geplaatst: {{ article.publication_date }}</div>
                <p class="text-white paragraph-text mt-1">{{ article.content }}</p>
                <div class="d-flex">
                    <router-link to="/" class="btn btn-secondary"><i class="fa fa-share me-1"></i> Delen</router-link>
                </div>
                <hr class="my-5"/>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from '@/components/FlexBannerComponent.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/nieuws",
                title: "Nieuws",
            }],
            articles: []
        }
    },
    async mounted() {
        fetch('https://www.vorm-vrij.nl/scripting/get_news.php').then((response) => {
            return response.json();
        }).then((response) => {
            this.articles = response;
        });
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.fitment {
    max-height: 512px;
    object-fit: cover;
    object-position: 0 40%;
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}
</style>