<template>
    <div :class="['position-fixed overlay p-3 text-center max-size-modal', openModal ? 'pe-all' : 'pe-none hidden']"
        id="overlay">
        <div
            :class="['modal-image mt-4 mx-auto text-white text-start rounded-0 position-relative', openModal ? '' : 'hide']">
            <i class="fa fa-times position-absolute" @click="closeModal"></i>
            <div class="p-4">
                <h2 class="border-0 h1 display-5 m-0">{{ title }}</h2>
            </div>
            <hr class="m-0" />

            <!-- Nieuw beeld -->
            <template v-if="purpose === 'add_sculpture'">
                <div class="p-4">
                    <div class="form-group mb-3">
                        <h4>Titel</h4>
                        <input type="text" placeholder="Titel beeld" class="form-control rounded-1">
                    </div>
                    <div class="form-group mb-3">
                        <h4>Beschrijving</h4>
                        <textarea class="form-control rounded-1" placeholder="Zet hier een beschrijving neer..."
                            rows="4"></textarea>
                    </div>

                    <div class="hr-line-dashed"></div>

                    <div class="form-group mb-3">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                            <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Beeld is
                                cursistenwerk</label>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                            <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Zichtbaar op
                                website</label>
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-lg-4 mb-3">
                        <div class="col col-lg-3 btn-text pt-lg-2">
                            Breedte (cm)
                        </div>
                        <div class="col mb-2 mb-lg-0">
                            <input type="number" class="form-control rounded-1">
                        </div>
                        <div class="col col-lg-3 btn-text pt-lg-2">Hoogte (cm)</div>
                        <div class="col">
                            <input type="number" class="form-control rounded-1">
                        </div>
                    </div>

                    <div class="hr-line-dashed"></div>

                    <div class="form-group mb-3">
                        <h4>Foto</h4>
                        <input type="file" accept="image/*;capture=camera">
                    </div>

                    <div class="hr-line-dashed"></div>

                    <div class="form-group mb-3">
                        <div class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i> Toevoegen!</div>
                    </div>
                </div>
            </template>

            <!-- Bestaande bewerken -->
            <template v-if="purpose === 'edit_sculpture'">
                <form method="POST" action="https://www.vorm-vrij.nl/scripting/add_sculpture.php">
                    <div class="p-4">
                        <div class="form-group mb-3">
                            <h4>Titel</h4>
                            <input type="text" placeholder="Titel beeld" class="form-control rounded-1" name="title"
                                :value="title">
                        </div>
                        <div class="form-group mb-3">
                            <h4>Beschrijving</h4>
                            <textarea class="form-control rounded-1" placeholder="Zet hier een beschrijving neer..."
                                rows="4" :value="description"></textarea>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Beeld is
                                    cursistenwerk</label>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Zichtbaar op
                                    website</label>
                            </div>
                        </div>

                        <div class="row row-cols-1 row-cols-lg-4 mb-3">
                            <div class="col col-lg-3 btn-text pt-lg-2">
                                Breedte (cm)
                            </div>
                            <div class="col mb-2 mb-lg-0">
                                <input type="number" class="form-control rounded-1" :value="width">
                            </div>
                            <div class="col col-lg-3 btn-text pt-lg-2">Hoogte (cm)</div>
                            <div class="col">
                                <input type="number" class="form-control rounded-1" :value="height">
                            </div>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <h4>Foto</h4>
                            <input type="file" accept="image/*;capture=camera">
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <div class="btn btn-gold fw-bold"><i class="fa fa-pencil me-1"></i> Aanpassen</div>
                            <div class="btn btn-danger fw-bold float-end"><i class="fa fa-trash me-1"></i> Verwijderen</div>
                        </div>
                    </div>
                </form>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';

export default {
    name: 'image-modal',
    props: ['title', 'purpose', 'description', 'direct_image_url', 'width', 'height', 'openModal'],
    data() {
        return {}
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        }
    },
    mounted() {
        $("#overlay").click((e) => {
            if ($(e.target).attr('id') === 'overlay')
                this.$emit('closeModal');
        });
    }
}
</script>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1500;
    transition: background-color 500ms ease;
}

.hide {
    opacity: 0;
}

.max-size-modal {
    max-height: calc(100vh);
    overflow-y: scroll;
}

.fa-times {
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
    transition: transform 400ms ease;
}

.fa-times:hover {
    transform: rotateZ(90deg) scale(125%);
}

.max-size {
    max-height: 62vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.max-size::-webkit-scrollbar {
    width: 0;
}

.hidden {
    background-color: transparent;
}

.modal-image {
    width: 100%;
    max-width: 640px;
    background-color: var(--bg-nella);
    box-shadow: 1px 2px 39px #000;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: opacity 400ms ease;
}</style>