<template>
    <div class="mb-5 d-block">
        <router-link v-for="route in routes" :key="route.id" :to="route.to" :class="['me-2 text-start mb-3 btn text-black fw-normal btn-text', currentRouteName === route.to ? 'btn-light': 'btn-gold']"><i :class="['fa me-1', route.icon]"></i> {{ route.text }}</router-link>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'admin-nav',
    data() {
        return {
            routes: [{
                to: '/admin',
                text: 'Dashboard',
                icon: 'fa-pie-chart',
            }, {
                to: '/admin/beeld-beheer',
                text: 'Beelden beheren',
                icon: 'fa-museum',
            }, {
                to: '/admin/categorieen',
                text: 'Categorieën beheren',
                icon: 'fa-bookmark',
            }, {
                to: '/',
                text: 'Nieuws beheren',
                icon: 'fa-newspaper',
            }, {
                to: '/',
                text: 'Gastenboek beheren',
                icon: 'fa-book-open',
            }, {
                to: '/',
                text: 'Tekst op website aanpassen',
                icon: 'fa-book',
            }
        ]
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.fullPath;
        }
    },
}
</script>