import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

function formatDate(date) {
    let retDate = [
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
        date.getMonth() + 1 < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1),
        date.getFullYear()
    ];

    return retDate.join('-');
}

app.config.globalProperties.$formatDate = formatDate;

/*eslint-disable*/

const protectedRoutes = ['/admin', '/admin/beeld-beheer', '/admin/categorieen'];
router.beforeEach((to) => {
    if(protectedRoutes.indexOf(to.fullPath) >= 0)
        router.push('/admin/login');
});

app.use(router)
    .mount('#app');